import * as React from 'react'

import { ResponsiveSliderSet } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  BannerSlider,
  BannerProps,
  BannerSliderProps,
} from '@thg-commerce/enterprise-widget-banner'
import { GlobalPrimaryBannerPicture } from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/GlobalPrimaryBannerPicture'

import { BaseWidgetProps } from '../types'
import { getPrimaryBannerProps } from './util'

interface ResponsiveSliderSetWithChildren
  extends Omit<ResponsiveSliderSet, 'banners'> {
  banners: Omit<GlobalPrimaryBannerPicture, 'id' | 'query'>[]
}

export type BannerSliderWidgetRendererProps = BaseWidgetProps &
  Omit<ResponsiveSliderSetWithChildren, 'query'> & { widgetIndex: number }

export const BannerSliderWidgetRenderer = (
  props: BannerSliderWidgetRendererProps,
) => {
  if (props.banners.length === 0 || undefined) {
    return null
  }

  const banners: BannerProps[] = props.banners.map((item) =>
    getPrimaryBannerProps(item),
  )

  const bannerSliderProps: BannerSliderProps = {
    banners,
    widgetIndex: props.widgetIndex,
    autoPlay: props.isAutoSlideOn !== 'false',
  }

  return <BannerSlider {...bannerSliderProps} />
}
