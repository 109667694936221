import * as React from 'react'

import {
  GlobalGeneralImageBanner,
  GlobalPrimaryBanner,
  GlobalPrimaryBannerWithList,
  GlobalPrimaryBannerWithTextOverlay,
  GlobalWidgetVega,
  GravityPicture,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { useTheme } from '@thg-commerce/enterprise-theme'
import {
  Banner,
  BannerProps,
  BoxHorizontalAlignment,
  TextHorizontalAlignment,
} from '@thg-commerce/enterprise-widget-banner'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

import { getPrimaryBannerProps } from './util'

export type BannerWidgetRendererProps = BaseWidgetProps &
  (
    | (Omit<GlobalPrimaryBanner, 'query'> & {
        picture: GravityPicture
        widgetIndex: number
      })
    | (Omit<GlobalGeneralImageBanner, 'query'> & {
        picture: GravityPicture
        widgetIndex: number
      })
    | (Omit<GlobalWidgetVega, 'query'> & {
        picture: GravityPicture
        widgetIndex: number
      })
    | (Omit<GlobalPrimaryBannerWithList, 'query'> & {
        picture: GravityPicture
        widgetIndex: number
      })
    | (Omit<GlobalPrimaryBannerWithTextOverlay, 'query'> & {
        picture: GravityPicture
        widgetIndex: number
      })
  )

export const BannerWidgetRenderer = (props: BannerWidgetRendererProps) => {
  const { lazy, fetchPriority } =
    props.widgetIndex > 1
      ? { lazy: true, fetchPriority: 'auto' as 'auto' }
      : { lazy: false, fetchPriority: 'high' as 'high' }

  if (props.__typename === WidgetTypes.GlobalGeneralImageBanner) {
    const bannerProps: BannerProps = {
      picture: props.picture,
      imageLink: props.linkUrl || undefined,
      containBanner: true,
    }
    return <Banner {...bannerProps} lazy={lazy} fetchPriority={fetchPriority} />
  }

  if (
    props.__typename === WidgetTypes.GlobalPrimaryBanner ||
    props.__typename === WidgetTypes.GlobalPrimaryBannerWithList ||
    props.__typename === WidgetTypes.GlobalPrimaryBannerWithTextOverlay
  ) {
    const bannerProps = getPrimaryBannerProps(props)
    const theme = useTheme()

    const enableBannerTextOverlay = !!(
      props.__typename === WidgetTypes.GlobalPrimaryBannerWithTextOverlay
    )

    return (
      <Banner
        {...bannerProps}
        lazy={lazy}
        fetchPriority={fetchPriority}
        marginBottom={theme.widget.banner.marginBottom}
        hideGap={theme.widget.banner?.hideGap}
        enableBannerTextOverlay={enableBannerTextOverlay}
      />
    )
  }

  if (props.__typename === WidgetTypes.GlobalWidgetVega) {
    const bannerProps: BannerProps = {
      title: {
        text: props.title || '',
      },
      subtitle: props.subTitle || '',
      ctas: [
        {
          text: props.button || '',
          emphasis: 'high',
          link: props.link || '',
        },
      ],
      textContainerOptions: {
        width: 'contained',
        contentAlignment: {
          box: (props.container === 'center'
            ? 'middle'
            : props.container) as BoxHorizontalAlignment,
          text: props.textAlign as TextHorizontalAlignment,
        },
      },
      highlight: props.highlight || '',
      picture: props.picture,
      spacingAdjust: true,
    }
    return <Banner {...bannerProps} lazy={lazy} fetchPriority={fetchPriority} />
  }

  return null
}
